import React, { useState, useEffect } from "react";
import { auth } from "../../firebaseConfig";
import axios from "axios";
import "./userprofile.css";
import './userprofile_mobile.css'
const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 600);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
export default function UserProfile() {
  const [username, setUsername] = useState("Guest");
  const [newUsername, setNewUsername] = useState("");
  const [bio, setBio] = useState("Bio:");
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [xboxGamertag, setXboxGamertag] = useState("");
  const [saveMessage, setSaveMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const serverUrl = "https://server.jumpnexus.org";
  const isMobile = useMobile();

    useEffect(() => {
        if (isMobile) {
            import('./userprofile_mobile.css'); 
        }
    }, [isMobile]);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        auth.onAuthStateChanged(async (user) => {
          if (user) {
            const token = await user.getIdToken();

            // Fetch username from Firestore
            const firebaseResponse = await axios.get(`${serverUrl}/userdata/${user.uid}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (firebaseResponse.status === 200) {
              const firebaseData = firebaseResponse.data;
              setUsername(firebaseData.username || "Guest");
              setNewUsername(firebaseData.username || "Guest");
            }

            // Fetch additional data from the backend
            const backendResponse = await axios.get(`${serverUrl}/userdata`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (backendResponse.status === 200) {
              const allUserData = backendResponse.data;
              const currentUserData = allUserData[user.uid];

              if (currentUserData) {
                setBio(currentUserData.bio || "Bio:");
                setProfilePicUrl(currentUserData.profilePicture || "https://via.placeholder.com/150");
                setBannerUrl(currentUserData.profileBanner || "https://via.placeholder.com/1000x200");
                setXboxGamertag(currentUserData.xboxGamertag || "");
              }
            }
          }
        });
      } catch (error) {
        // console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const saveBio = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        `${serverUrl}/userdata`,
        { uid: auth.currentUser.uid, bio }, // Sending only the bio field
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        setSaveMessage("Bio updated successfully!");
      }
    } catch (error) {
      // console.error("Error saving bio:", error);
      setSaveMessage("Error saving bio. Please try again.");
    }
  };
  
  const saveProfilePic = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        `${serverUrl}/userdata`,
        { uid: auth.currentUser.uid, profilePicture: profilePicUrl }, // Sending only the profile picture
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        setSaveMessage("Profile picture updated successfully!");
      }
    } catch (error) {
      // console.error("Error saving profile picture:", error);
      setSaveMessage("Error saving profile picture. Please try again.");
    }
  };
  
  const saveBanner = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        `${serverUrl}/userdata`,
        { uid: auth.currentUser.uid, profileBanner: bannerUrl }, // Sending only the banner
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        setSaveMessage("Profile banner updated successfully!");
      }
    } catch (error) {
      // console.error("Error saving profile banner:", error);
      setSaveMessage("Error saving profile banner. Please try again.");
    }
  };
  
  const saveGamertag = async () => {
    try {
      // Regular expression to allow alphanumeric characters and spaces
      const isValidGamertag = /^[a-zA-Z0-9\s]+$/.test(xboxGamertag);
  
      if (!isValidGamertag) {
        setSaveMessage("Gamertag can only contain letters, numbers, and spaces. No special characters are allowed.");
        return;
      }
  
      // Check if the gamertag exists in the external dataset
      const datasetResponse = await axios.get("https://backend.jumpnexus.org:444/dataset");
      if (datasetResponse.status === 200) {
        const allUsersInDataset = datasetResponse.data;
  
        // Check if the gamertag exists in the dataset
        const gamertagExistsInDataset = Object.values(allUsersInDataset).some(
          (gamertag) => typeof gamertag === "string" && gamertag.toLowerCase() === xboxGamertag.toLowerCase()
        );
  
        if (!gamertagExistsInDataset) {
          setSaveMessage("You need to join a jump lobby for at least 5 minutes before setting your gamertag.");
          return;
        }
      } else {
        setSaveMessage("Failed to verify gamertag eligibility. Please try again later.");
        return;
      }
  
      const token = await auth.currentUser.getIdToken();
  
      // Check if the gamertag is already in use on the website
      const userdataResponse = await axios.get(`${serverUrl}/userdata`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (userdataResponse.status === 200) {
        const allUsers = userdataResponse.data;
  
        // Check if the gamertag is already in use by another account
        const isDuplicate = Object.values(allUsers).some(
          (user) =>
            user.xboxGamertag &&
            user.xboxGamertag.toLowerCase() === xboxGamertag.toLowerCase()
        );
  
        if (isDuplicate) {
          setSaveMessage("Gamertag is already in use. Please submit a ticket for assistance if you feel this is an error.");
          return;
        }
      } else {
        setSaveMessage("Failed to fetch gamertag data from the website. Please try again later.");
        return;
      }
  
      // Save the new gamertag if all checks pass
      const saveResponse = await axios.post(
        `${serverUrl}/userdata`,
        { uid: auth.currentUser.uid, xboxGamertag },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (saveResponse.status === 200) {
        setSaveMessage("Gamertag updated successfully!");
      }
    } catch (error) {
      // console.error("Error saving gamertag:", error);
      setSaveMessage("Error saving gamertag. Please try again.");
    }
  };
  
  
  
  
  

  const handleUsernameChange = async () => {
    try {
      if (newUsername.trim() === username) {
        setSaveMessage("New username is the same as the current username.");
        return;
      }

      const response = await axios.post(
        `${serverUrl}/change-username`,
        { username: newUsername },
        {
          headers: {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setUsername(newUsername);
        setSaveMessage("Username updated successfully!");
      }
    } catch (error) {
      // console.error("Error updating username:", error);
      setSaveMessage("Error updating username. Please try again.");
    }
  };

  if (loading) {
    return <div className="user-profile">Loading...</div>;
  }

  return (
    <div className="profile-user-profile">
      <div className="profile-banner" style={{ backgroundImage: `url(${bannerUrl})` }}>
        <img src={profilePicUrl} alt="Profile" className="profile-profile-pic half-overlap" />
      </div>
      <div className="profile-profile-info">
        <div className="profile-username">{username}</div>
        <div className="profile-bio">{bio}</div>
        <div className="profile-xbox-gamertag">Xbox Live Gamertag: {xboxGamertag || "Not set"}</div>
      </div>
      <div className="profile-edit-section">
        <div className="profile-edit-field">
          <label>New Username:</label>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
          />
        </div>
        <button onClick={handleUsernameChange} className="profile-save-button">
          Save Username
        </button>
        <div className="profile-edit-field">
          <label>Bio:</label>
          <input type="text" value={bio} onChange={(e) => setBio(e.target.value)} />
        </div>
        <button onClick={saveBio} className="profile-save-button">
          Save Bio
        </button>
        <div className="profile-edit-field">
          <label>Profile Picture URL:</label>
          <input
            type="text"
            value={profilePicUrl}
            onChange={(e) => setProfilePicUrl(e.target.value)}
          />
        </div>
        <button onClick={saveProfilePic} className="profile-save-button">
          Save Profile Picture
        </button>
        <div className="profile-edit-field">
          <label>Profile Banner URL:</label>
          <input
            type="text"
            value={bannerUrl}
            onChange={(e) => setBannerUrl(e.target.value)}
          />
        </div>
        <button onClick={saveBanner} className="profile-save-button">
          Save Banner
        </button>
        <div className="profile-edit-field">
          <label>Xbox Live Gamertag:</label>
          <input
            type="text"
            value={xboxGamertag}
            onChange={(e) => setXboxGamertag(e.target.value)}
          />
        </div>
        <button onClick={saveGamertag} className="profile-save-button">
          Save Gamertag
        </button>
      </div>
      {saveMessage && <p className="profile-save-message">{saveMessage}</p>}
    </div>
  );
}