import { useState, useEffect } from "react";
import { default as NavBar } from "./components/NavBar";
import ChatOverlay from "./components/ChatOverlay";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig";
import axios from "axios"; // Assuming you use Axios for backend requests
import "./App.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("Guest");

  useEffect(() => {
    // Monitor Firebase auth state
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);

        try {
          // Fetch username from your backend or Firestore
          const token = await user.getIdToken();
          const response = await axios.get(`/userdata/${user.uid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setUsername(response.data.username || "Guest");
        } catch (error) {
          // console.error("Error fetching username:", error);
          setUsername("Guest"); // Fallback username
        }
      } else {
        setIsLoggedIn(false);
        setUsername("Guest");
      }
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  return (
    <div className="App">
      <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
        <ChatOverlay username={username} />
      </NavBar>
    </div>
  );
}

export default App;
