import React, { useState, useEffect, useRef } from "react";
import { auth } from "../firebaseConfig";
import axios from "axios";
import { Link } from "react-router-dom"; // For user profile navigation
import "./ChatOverlay.css";
const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 600);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
const ChatOverlay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [username, setUsername] = useState("Guest");
  const [profilePicture, setProfilePicture] = useState("default-profile-pic-url"); // Default profile picture
  const chatWindowRef = useRef(null);
  const ws = useRef(null);
  const lastMessageRef = useRef(null);

  // Function to fetch username and profile picture
  // Function to fetch username and profile picture
const fetchUsername = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      setUsername("Guest");
      setProfilePicture("default-profile-pic-url"); // Reset profile picture if user is not logged in
      return;
    }

    const token = await user.getIdToken();

    // Fetch the entire user data object
    const response = await axios.get('/userdata', { // Fixed URL string
      headers: {
        Authorization: `Bearer ${token}`, // Fixed template string
      },
    });

    // Access the user data by UID
    const userData = response.data[user.uid];
    if (userData) {
      setUsername(userData.username || "Anonymous"); // Set the username (xboxGamertag)
      setProfilePicture(userData.profilePicture || "default-profile-pic-url"); // Set the profile picture
    } else {
      // console.error("User data not found for UID:", user.uid);
      setUsername("Anonymous");
      setProfilePicture("default-profile-pic-url");
    }
  } catch (error) {
    // console.error("Error fetching user data:", error);
    setUsername("Anonymous");
    setProfilePicture("default-profile-pic-url");
  }
};



  

  // Detect if the device is mobile
  const isMobile = useMobile();

  useEffect(() => {
    if (isMobile) {
      import("./ChatOverlay_Mobile.css");
    }
  }, [isMobile]);

  // Fetch username on component mount
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUsername();
      } else {
        setUsername("Guest");
        setProfilePicture("default-profile-pic-url");
      }
    });
  }, []);

  // Establish WebSocket connection
  useEffect(() => {
    ws.current = new WebSocket("wss://server.jumpnexus.org");

    ws.current.onopen = () => {
      // console.log("WebSocket connection opened.");
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setMessages((prev) => {
        // Avoid adding duplicate messages
        if (prev.find((msg) => msg.timestamp === message.timestamp && msg.uid === message.uid)) {
          return prev;
        }
        return [...prev, message];
      });
    };

    ws.current.onclose = () => {
      // console.log("WebSocket connection closed.");
    };

    ws.current.onerror = (error) => {
      // console.error("WebSocket error:", error);
    };

    return () => {
      ws.current.close();
    };
  }, []);

  // Fetch messages from the backend
  useEffect(() => {
    const fetchMessages = async (user) => {
      try {
          if (!user) {
              // console.warn("User not authenticated yet.");
              return;
          }
  
          // Fetch messages from the backend
          const response = await axios.get("/get-messages-google");
          const messages = response.data.messages || [];
  
          // Fetch user data with authorization token
          const token = await user.getIdToken();
          const userDataResponse = await axios.get("/userdata", {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
  
          const userData = userDataResponse.data;
  
          // Enrich messages with profile pictures
          const enrichedMessages = messages.map((msg) => ({
              ...msg,
              profilePicture: userData[msg.uid]?.profilePicture || "default-profile-pic-url",
          }));
  
          setMessages(enrichedMessages);
      } catch (error) {
          // console.error("Error fetching messages or user data:", error);
      }
  };
  
  
    // Wait for the user to authenticate before fetching messages
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchMessages(user); // Call fetchMessages with the authenticated user
      } else {
        // console.warn("User not authenticated.");
      }
    });
  
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  // Auto-scroll to the last message
  useEffect(() => {
    if (isOpen && messages.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [messages, isOpen]);

  // Close chat window on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatWindowRef.current && !chatWindowRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Send a new message
  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
        const user = auth.currentUser;

        if (!user) {
            alert("You need to log in to send a message.");
            return;
        }

        // Ensure we have the profile picture before sending the message
        if (!profilePicture || profilePicture === "default-profile-pic-url") {
            await fetchUserProfilePicture(user); // Fetch and set profile picture if missing
        }

        const token = await user.getIdToken();

        const messageData = {
            uid: user.uid,
            username, // Use the updated username state
            profilePicture, // Use the updated profile picture state
            message: newMessage.trim(),
            timestamp: new Date().toISOString(),
        };

        // Send the message to the server
        await axios.post("/save-message-google", messageData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Broadcast the message via WebSocket if open
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(messageData));
        }

        // Update the local message list instantly
        setMessages((prev) => [...prev, messageData]);
        setNewMessage("");
    } catch (error) {
        // console.error("Error sending message:", error);
    }
};

const fetchUserProfilePicture = async (user) => {
  try {
      const token = await user.getIdToken();

      // Fetch user data from the backend
      const response = await axios.get(`/userdata/${user.uid}`, {
          headers: { Authorization: `Bearer ${token}` },
      });

      const userData = response.data;

      // Update the profile picture in state
      setProfilePicture(userData.profilePicture || "default-profile-pic-url");
  } catch (error) {
      // console.error("Error fetching user profile picture:", error);
      setProfilePicture("default-profile-pic-url");
  }
};


  // Toggle chat window
  const toggleChat = () => setIsOpen(!isOpen);

  return (
    <div className="chat-overlay-container">
      <button className="chat-toggle-button" onClick={toggleChat}>
        {isOpen ? "✖" : "💬"}
      </button>
      {isOpen && (
        <div className="chat-window" ref={chatWindowRef}>
          <div className="chat-header">
            
            <h4>Website Chat</h4>
            {isMobile && (
              <button
                className="close-chat-button"
                onClick={() => setIsOpen(false)}
              >
                ✖
              </button>
            )}
          </div>
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className="chat-message"
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <div className="chat-message-header">
                <img
    src={msg.profilePicture || "default-profile-pic-url"}
    alt={`${msg.username}'s profile`}
    className="chat-profile-picture"
/>

                  <div className="chat-username">
                    <Link to={`/user/${msg.username}`} className="chat-username-link">
                      {msg.username}
                    </Link>
                  </div>
                </div>
                <div className="chat-text">{msg.message}</div>
                <div className="chat-timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="chat-input-container">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              placeholder="Type your message..."
              className="chat-input"
            />
            <button className="send-button" onClick={sendMessage}>
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatOverlay;

