import "./CGB.css";
import { useState, useEffect } from "react";
import axios from "axios";
import mapsData from "../MapIDList/maps.json"; // Importing the JSON file
import { default as sandbox } from "../WebsitePics/sandbox.png";
import { default as foundry } from "../WebsitePics/foundry.png";
import { default as avalanche } from "../WebsitePics/avalanche.png";
import { default as blackout } from "../WebsitePics/blackout.png";
import { default as breakneck } from "../WebsitePics/breakneck.png";
import { default as breakpoint } from "../WebsitePics/breakpoint.png";
import { default as highlands } from "../WebsitePics/highlands.png";
import { default as icebox } from "../WebsitePics/icebox.png";
import { default as ridgeline } from "../WebsitePics/ridgeline.png";
import { default as swordbase } from "../WebsitePics/swordbase.png";
import { default as tempest } from "../WebsitePics/tempest.png";
import { default as waterfall } from "../WebsitePics/waterfall.png";
import { default as forgeworld } from "../WebsitePics/forgeworld.png";
import { default as failcath3 } from "../WebsitePics/failcath3.png";
import { default as failcathr } from "../WebsitePics/failcathr.png";

const DATA_ENDPOINT = "https://jumpnexus.azurewebsites.net/api/GetCGBServerList?";
const GAMERTAGS_ENDPOINT = "https://backend.jumpnexus.org:444/dataset";

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 600);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

function truncateString(str, maxLength) {
  return str?.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;
}

async function fetchFilteredServers() {
  try {
    const response = await axios.get(DATA_ENDPOINT);
    const data = response.data;

    if (data && data.Games) {
      return data.Games.map((server, index) => ({
        ...server,
        uniqueID: `${server.ID || "server"}-${index}`, // Add a unique ID for each server
        server_start_time: server.server_start_time || null,
        session_start_time: server.session_start_time || null,
        players: server.players || [], // Include players array
        banned: server.banned || [],
        playlistVariants: {
          gameVariants: server.playlistVariants?.gameVariants || [],
          mapVariants: server.playlistVariants?.mapVariants || [],
          playlists: server.playlistVariants?.playlists || [],
        }, // Include playlistVariants with nested data
      }));
    }
    return [];
  } catch (error) {
    console.error("Error fetching servers:", error);
    return [];
  }
}

async function fetchGamertags(xuids) {
  try {
    const response = await axios.get(GAMERTAGS_ENDPOINT);
    const gamertagData = response.data;
    return xuids.map((xuid) => gamertagData[xuid] || "Unknown Player");
  } catch (error) {
    console.error("Error fetching gamertags:", error);
    return xuids.map(() => "Unknown Player");
  }
}

async function fetchBannedGamertags(xuids) {
  try {
    const response = await axios.get(GAMERTAGS_ENDPOINT); // Corrected to use GAMERTAGS_ENDPOINT
    const bannedData = response.data;
    return xuids.map((xuid) => bannedData[xuid] || "Unknown Player");
  } catch (error) {
    console.error("Error fetching banned gamertags:", error);
    return xuids.map(() => "Unknown Player");
  }
}

function getMapImage(builtinMapId) {
  // Default image for unknown maps
  if (builtinMapId === undefined || builtinMapId === null) return sandbox;

  // Find the map data based on builtinMapId
  const mapData = mapsData.maps.find((map) => map.MapId === builtinMapId);
  
  if (!mapData) return sandbox; // Default if map not found

  // Map names to image imports
  const mapImages = {
    Sandbox: sandbox,
    Foundry: foundry,
    Avalanche: avalanche,
    Blackout: blackout,
    Breakneck: breakneck,
    Breakpoint: breakpoint,
    Highlands: highlands,
    Icebox: icebox,
    Ridgeline: ridgeline,
    SwordBase: swordbase,
    Tempest: tempest,
    Waterfall: waterfall,
    ForgeWorld: forgeworld,
  };

  // Match the map name (case-insensitive)
  const mapName = mapData.MapName.toLowerCase();
  for (const [key, image] of Object.entries(mapImages)) {
    if (mapName.includes(key.toLowerCase())) {
      return image;
    }
  }

  return sandbox; // Default if no match
}

function findCurrentMapName(game) {
  let currentMapName = "Unknown";

  if (game.currentGame) {
    let currentMatchId = game.currentGame.match_id;

    if (currentMatchId === -1) return currentMapName;

    for (const playlist of game.playlistVariants?.playlists) {
      const mapVariantIds = playlist.mapVariantIds;
      const length = mapVariantIds.length;

      if (currentMatchId < length) {
        const mapVariantIndex = mapVariantIds[currentMatchId];
        if (mapVariantIndex < game.playlistVariants.mapVariants.length) {
          const mapVariant = game.playlistVariants.mapVariants[mapVariantIndex];
          if (mapVariant && mapVariant.name) {
            currentMapName = mapVariant.name;
          }
          return currentMapName;
        }
      } else {
        currentMatchId -= length;
      }
    }
  }
  return currentMapName;
}

function formatTimeLimit(timeLimit) {
  if (timeLimit === -1) {
    return "Unlimited";
  } else if (timeLimit === 60) {
    return "60 mins";
  } else if (timeLimit < 60) {
    return `${timeLimit} seconds`;
  } else {
    return `${Math.floor(timeLimit / 60)} mins`;
  }
}

function formatDateWithoutSeconds(timestamp) {
  if (!timestamp) return "Unknown";
  const date = new Date(timestamp * 1000);
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export default function CGB() {
  const [game, setGame] = useState("Halo 3");
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [gamertags, setGamertags] = useState([]);
  const [bannedGamertags, setBannedGamertags] = useState([]);
  const isMobile = useMobile();

  useEffect(() => {
      if (isMobile) {
          import('./CGB_Mobile.css'); 
      }
  }, [isMobile]);

  useEffect(() => {
    const fetchServers = async () => {
      const allServers = await fetchFilteredServers();
      setServers(allServers);
    };
    fetchServers();
  }, []);

  useEffect(() => {
    const fetchAndSetGamertags = async () => {
      if (selectedServer) {
        const fetchedGamertags = await fetchGamertags(selectedServer.players);
        setGamertags(fetchedGamertags);
        const fetchedBannedGamertags = await fetchBannedGamertags(selectedServer.banned);
        setBannedGamertags(fetchedBannedGamertags);
      }
    };
    fetchAndSetGamertags();
  }, [selectedServer]);

  const filteredServers = servers.filter((server) => {
    const isHalo3Map = server.currentGame?.builtinMapId >= 69 && server.currentGame?.builtinMapId <= 102;
    const isHaloReachMap = server.currentGame?.builtinMapId >= 178 && server.currentGame?.builtinMapId <= 219;
  
    const hasKeywords = server.playlistVariants?.gameVariants?.some((variant) => {
      const name = variant.name?.toLowerCase() || '';
      return (
        (name.includes('jump') ||
          name.includes('jumping') ||
          name.includes('jumps') ||
          name.includes('jumpy') ||
          name.includes('parkour') ||
          name.includes('parkour infinite')) &&
        !name.includes('jump rope')
      );
    });
  
    if (game === "Halo 3") {
      return isHalo3Map && hasKeywords;
    }
  
    if (game === "Halo: Reach") {
      return isHaloReachMap && hasKeywords;
    }
  
    return false;
  });
  
  

  useEffect(() => {
    const handleNavbarOpened = () => {
      if (isMobile) {
        setSidebarVisible(false); // Close the sidebar when the navbar is opened
      }
    };
  
    window.addEventListener('navbarOpened', handleNavbarOpened);
  
    return () => {
      window.removeEventListener('navbarOpened', handleNavbarOpened);
    };
  }, [isMobile]);
  
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    if (isMobile) {
      // Emit an event to close the navbar
      window.dispatchEvent(new Event('sidebarOpened'));
    }
  };

  return (
    <div className="cgb-container">
      <button
        className={`cgb-new-sidebar-toggle-button ${sidebarVisible ? "visible" : "hidden"}`}
        onClick={toggleSidebar}
      >
        {sidebarVisible ? "❯" : "❮"}
      </button>

      <div className={`cgb-right-sidebar ${sidebarVisible ? "visible" : "hidden"}`}>
  <div className="cgb-game-switcher">
    <button
      className={`cgb-game-switch-button ${game === "Halo 3" ? "active" : ""}`}
      onClick={() => setGame("Halo 3")}
    >
      Halo 3
    </button>
    <button
      className={`cgb-game-switch-button ${game === "Halo: Reach" ? "active" : ""}`}
      onClick={() => setGame("Halo: Reach")}
    >
      Halo: Reach
    </button>
  </div>

  {filteredServers.length > 0 ? (
    <ul className="cgb-server-list">
      {filteredServers.map((server) => (
        <li
          key={server.uniqueID}
          className={`cgb-server-item ${selectedServer?.uniqueID === server.uniqueID ? "active" : ""}`}
          onClick={() => {setSelectedServer(server);if (isMobile) {
            setSidebarVisible(!sidebarVisible);
        }}}
        >
          {truncateString(server.session_name, 25)}
        </li>
      ))}
    </ul>
  ) : (
    <div className="cgb-no-servers-available" onClick={() => {if (isMobile) {
      setSidebarVisible(!sidebarVisible);
  }}}      >
      <img src={game === "Halo 3" ? failcath3 : failcathr} alt="No Servers Available" />
      <p>No servers available for {game} at the moment. Please check back later! Click here to close me on mobile :D</p>
    </div>
  )}
</div>

      <div className={`cgb-main-content ${sidebarVisible ? "cgb-right-sidebar-visible" : "cgb-right-sidebar-hidden"}`}>
        {selectedServer ? (
          <div className="cgb-server-details">
          <img src={getMapImage(selectedServer.currentGame?.builtinMapId)} alt="Map" className="current-map-image" />
          <h2>{selectedServer.session_name}</h2>
          <div className="cgb-server-grid">
            <div className="cgb-player-list-container">
              <p><strong>Players:</strong> {selectedServer.players.length}/{selectedServer.max_players}</p>
              {gamertags.length > 0 ? (
                <ul className="cgb-player-list">
                  {gamertags.map((gamertag, index) => (
                    <li key={index}>{gamertag}</li>
                  ))}
                </ul>
              ) : (
                "No players available"
              )}
            </div>
            <div className="cgb-server-info">
              <div className="cgb-info-item">
                <strong>Region:</strong> <span>{selectedServer.server_region || "Unknown"}</span>
              </div>
              <div className="cgb-info-item">
                <strong>Time Limit:</strong> <span>{formatTimeLimit(selectedServer.session_time_limit)}</span>
              </div>
              <div className="cgb-info-item">
                <strong>Server Start Time:</strong>{" "}
                <span>{formatDateWithoutSeconds(selectedServer.server_start_time)}</span>
              </div>
              <div className="cgb-info-item">
                <strong>Session Start Time:</strong>{" "}
                <span>{formatDateWithoutSeconds(selectedServer.session_start_time)}</span>
              </div>
              <div className="cgb-info-item">
                <strong>Banned Players:</strong>
                {bannedGamertags.length > 0 ? (
                  <ul>
                    {bannedGamertags.map((gamertag, index) => (
                      <li key={index}>{gamertag}</li>
                    ))}
                  </ul>
                ) : (
                  "None"
                )}
              </div>
            </div>
            </div>
            <p className="cgb-playlists-title">
              <strong>Playlists:</strong>
            </p>
            
               <div className="cgb-playlist-wrapper">
               {selectedServer.playlistVariants.playlists.map((playlist, index) => (
                 <div key={index} className="cgb-playlist-container">
                   <p>
                     <strong>Playlist Name:</strong> {playlist.name}
                   </p>
                   <p>
                     <strong>Maps:</strong>
                   </p>
                   <ul>
                     {playlist.mapVariantIds.map((mapId, mapIndex) => {
                       const mapVariant = selectedServer.playlistVariants.mapVariants[mapId];
                       const currentMapName = findCurrentMapName(selectedServer);
                       const isCurrentMap = mapVariant?.name === currentMapName;
           
                       return (
                         <li
                           key={mapIndex}
                           className={`cgb-playlist-map ${isCurrentMap ? "cgb-current-map" : ""}`}
                         >
                           {mapVariant?.name || "Unknown Map"}
                         </li>
                       );
                     })}
                   </ul>
                 </div>
               ))}
             </div>
            
          </div>
        ) : (
          <div className="cgb-intro-page">
            <div className="cgb-intro-content">
              <h1>Welcome to the CGB Server Browser</h1>
              <p className="cgb-intro-description">
                The CGB Server Browser allows you to view available game servers, track server details, and join in on the action. Select a server from the list on the right to view more information, including the current players, server settings, and map details.
              </p>
              
              <div className="cgb-navigation-info">
                <h2>How to Navigate:</h2>
                <ul>
                  <li>Use the right-hand sidebar to browse and select game servers.</li>
                  <li>Switch between <strong>Halo 3</strong> and <strong>Halo: Reach</strong> using the buttons at the top of the sidebar.</li>
                  <li>Click on a server to view its detailed information including player lists, playlists, and more.</li>
                </ul>
              </div>

              
            </div>
          </div>
        )}
      </div>
    </div>
  );
}