import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { auth } from "../firebaseConfig"; // Ensure Firebase is properly configured and imported
import "./pages/userprofile.css";
import "./pages/userprofile_mobile.css";

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export default function UserProfileView() {
  const { username } = useParams(); // Extract username from the URL
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true); // Add loading state for authentication
  const [error, setError] = useState(null);
  const serverUrl = "https://server.jumpnexus.org";
  const isMobile = useMobile();

  useEffect(() => {
    if (isMobile) {
      import("./pages/userprofile_mobile.css");
    }
  }, [isMobile]);

  // Check authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthLoading(false); // Auth checked, proceed to fetch profile
      } else {
        setError("You must be logged in to view profiles.");
        setAuthLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch profile data after authentication is checked
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        setError(null);

        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error("You must be logged in to view profiles.");
        }

        const token = await currentUser.getIdToken();

        // Fetch UID using the username
        const usernameResponse = await axios.get(
          `${serverUrl}/userdata-by-username/${username}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!usernameResponse.data.uid) {
          throw new Error("Failed to fetch UID for the username.");
        }

        const { uid } = usernameResponse.data;

        // Fetch profile data using the UID
        const profileResponse = await axios.get(`${serverUrl}/userdata`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const profileDetails = profileResponse.data[uid];
        if (!profileDetails) {
          throw new Error("Profile data not found for the UID.");
        }

        setProfileData({
          ...profileDetails,
          username: usernameResponse.data.username || "Guest",
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (!authLoading) {
      fetchProfileData(); // Fetch profile only after auth check is complete
    }
  }, [username, authLoading]);

  if (authLoading || loading) {
    return <div className="user-profile">Loading...</div>;
  }

  if (error) {
    return <div className="user-profile">Error: {error}</div>;
  }

  if (!profileData) {
    return <div className="user-profile">Profile not found.</div>;
  }

  return (
    <div className="profile-user-profile">
      <div
        className="profile-banner"
        style={{
          backgroundImage: `url(${profileData.profileBanner || "https://via.placeholder.com/1000x200"})`,
        }}
      >
        <img
          src={profileData.profilePicture || "https://via.placeholder.com/150"} 
          alt="Profile"
          className="profile-profile-pic half-overlap"
        />
      </div>
      <div className="profile-profile-info">
        <div className="profile-username">{profileData.username || "Guest"}</div>
        <div className="profile-bio">{profileData.bio || "No bio available."}</div>
        <div className="profile-xbox-gamertag">
          Xbox Live Gamertag: {profileData.xboxGamertag || "Not set"}
        </div>
      </div>
    </div>
  );
}
