import React, { useEffect, useState } from 'react';
import './DiscordServers.css';
import './DiscordServers_Mobile.css'

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const DiscordServers = () => {
    const isMobile = useMobile();

    useEffect(() => {
        if (isMobile) {
            import('./DiscordServers_Mobile.css');
        }
    }, [isMobile]);

    const servers = [
        { id: '1040475128091385876', name: "Shinobi" },
        { id: '932741816548229120', name: "Halo Hangout" },
    ];


    const classNameForServer = (name) => {
        return name.toLowerCase().replace(/\s+/g, '-');
    };

    return (
        <div>
            <h1>Jumping Discord Servers</h1>
            <div className="discord-container">
                {servers.map((server, index) => (
                    <div key={index} className={`discord-server-widget ${classNameForServer(server.name)}`}>
                        <h2 className="discord-server-name">{server.name}</h2>
                        <iframe
                        title={`${server.name} Server`}
                        src={`https://discord.com/widget?id=${server.id}&theme=dark`}
                        width="100%"
                        height="500" /* Keep height consistent */
                        allowTransparency="true"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                        </iframe>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DiscordServers;
